<template>
	<div class="page">
		<img src="../../assets/cic.png" />
		<div class="title">
			{{lang==6?'客户互动服务中心':'Customer Interaction Center'}}
			<p class="yellowLine"></p>
		</div>
		<div class="con" v-if="lang==6">
			<div class="left">
				“客户互动服务中心”是宝爱捷（中国）集团旗下的集中式客户服务平台，旨在为集团经销商的客户提供专业，安全，高效，可持续的高品质服务，为客户提供购车前、购车中和购车后的全程贴心支持。宝爱捷（中国）非常重视客户们的满意度，这一直都是集团组织发展战略中最重要的部分。<br><br>
				我们坚持以客户为中心，快速响应客户需求。我们的服务宗旨“持续为客户创造长期价值，进而成就客户”。
				<br><br>
				未来，集团客户互动服务中心将秉承以“客户为中心”的服务理念，打造“一站式体验的、专业、亲和、诚信”客户服务品牌，以专业的人才，周到便捷体贴的服务，努力让每位客户满意。<br><br>
				同时，“集团客户互动服务中心”以不断自主创新、超越顾客期望为愿景，力争以车为桥梁与顾客建立终身信赖的关系。我们因客户而存在，所以我们期望成为客户心中首选，心中最佳。我们期待成为汽车零售领域的标杆！
				<br><br>
				<p class="phone">
					宝爱捷 CIC 服务热线：95078911
				</p>
			</div>
			<div class="right">
				宝爱捷客户互动服务中心支持以电话，企业微信，小程序等多种途径为您提供如下服务：<br>
				<ul>
					<li>旗下所有品牌新车信息咨询；</li>
					<li>试驾预约；</li>
					<li>保养预约；</li>
					<li>道路救援；</li>
					<li>车辆使用咨询；</li>
					<li>客户满意度调研；</li>
					<li>客户提醒关怀活动通知；</li>
					<li>厂家召回通知；</li>
					<li>保养到期提醒；</li>
					<li>各类优惠活动通知等。</li>
				</ul>
			</div>
		</div>

		<div class="con" v-else>
			<div class="left">
				Customer Interaction Center (CIC) is a centralized customer service platform under PAIG (China). We are
				committed to provide customers with professional, safe, efficient, and sustainable high-quality
				services. PAIG (China) highly values the customer experience and satisfaction, which is also the most
				important part of our group strategy.<br><br>
				We are customer-oriented and fast reaction to customer demands. Our service tenet is "dedication to
				creating long-term value for customers".
				<br><br>
				In the future, CIC will continue to serve our customers as a reputed service provider one-stop service
				with customer-oriented, professional, considerate, and sincerity.<br><br>
				CIC will endeavor to realize its vision of "with continuous innovation to exceed customers' expectation.
				Establishing lifelong and trusted connection with customers through vehicles". We will develop an
				integrated and efficient service solution based on the principle of "fully dedicating to
				customer-oriented services" and build a trustworthy brand image of PAIG (China) and become the benchmark
				in automotive retail area.
				<br><br>
				<p class="phone">
					PAIG CIC hotline: 95078911
				</p>
			</div>
			<div class="right">
				You can always reach us through telephone, Wecom or mini program. And we provide you support on:<br>
				<ul>
					<li>New car information;</li>
					<li>Test drive appointment;</li>
					<li>Aftersales service appointment;</li>
					<li>Roadside assistance;</li>
					<li>Vehicle usage inquiry;</li>
					<li>Customer satisfaction survey;</li>
					<li>Customer care reminder;</li>
					<li>OEM recall notice;</li>
					<li>Maintenance reminder;</li>
					<li>Other company campaigns. </li>
				</ul>
			</div>
		</div>


	</div>
</template>

<script>
	import mixinLang from '../../components/mixin.js'
	export default {
		mixins: [mixinLang],
		data() {
			return {
				info: {}
			}
		},
		methods: {
			// getInfo() {
			// 	this.$api.getConfigure({
			// 		type: 8
			// 	}).then(res => {
			// 		this.info = res.data
			// 	})
			// }
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		padding-top: 0;
		padding-bottom: 60px;
		position: relative;
	}

	.yellowLine {
		margin: 50px 0 40px;
	}

	.con {
		margin: 0 60px;
		background-color: #EEEEEE;
		border-radius: 16px;
		padding: 56px 51px 40px;
		font-family: SourceHanSansCN-Normal, sans-serif;
		font-size: 20px;
		font-weight: 350;
		line-height: 36px;
		line-height: 46px;
		display: flex;
		justify-content: space-between;
		position: relative;

		&::after {
			content: '';
			height: 100%;
			width: 2px;
			background: url(../../assets/line.png) no-repeat top/100% 100%;
			position: absolute;
			left: 1100px;
			top: 0;
		}
	}

	.left {
		width: 980px;

		p {
			color: #97233F;
		}
	}

	.right {
		width: 560px;
	}

	.title {
		font-family: Montserrat-Bold, sans-serif;
		font-size: 32px;
		font-weight: bold;
		padding: 0 111px;
		margin-top: 60px;
		color: #FFF;
		text-transform: uppercase;
	}

	img {
		width: 100%;
		vertical-align: middle;
	}

	.tit {
		font-size: 22px;
		font-weight: bold;
		font-family: Montserrat-Bold, sans-serif;
		text-transform: uppercase;
	}

	.titBig {
		font-family: Montserrat-Bold, sans-serif;
		margin-top: 20px;
	}

	ul {
		margin-left: 25px;
		font-family: SourceHanSansCN-Bold, sans-serif;
		margin-bottom: 20px;

		li::marker {
			color: #97233F;
			font-size: 30px;
		}
	}

	.phone {
		font-size: 36px;
		font-weight: bold;
	}
</style>